import axios from '@/axios.js'

export default {
    /* Touristic object  */
    getTouristicObjects: async (filters = [], page = 1, per_page) => {
        let query = ""
        if (filters.length > 0) {
          query += ',' + filters.map(filter => {
            return `${filter.field}:${filter.value}`
          }).join(',')
        }
        try {
            const response = await axios.editorapi.get(`/v4/resorts/touristic-objects/`, { params: {
                q: query,
                per_page: per_page,                
                page
              }
            })

            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    updateTouristicObject: async (touristicObjectUuid, content) => {      
      try {
          const response = await axios.editorapi.patch(
              `v4/resorts/touristic-objects/${touristicObjectUuid}`, 
              content
          )          
          return response
      } catch (error) {
          throw new Error(error)
      }
    },
    deleteTouristicObject: async (touristicObjectUuid) => {
      try {
        await axios.editorapi.delete(`v4/resorts/touristic-objects/${touristicObjectUuid}`)
      } catch (error) {
        throw new Error(error)
      }
    },
    getTouristicObject: async (touristicObjectUuid) => {
      try {
       return  await axios.editorapi.get(`v4/resorts/touristic-objects/${touristicObjectUuid}`)
      } catch (error) {
        throw new Error(error)
      }
    },
    createTouristicObject: async (touristicObjectUuid,data) => {
      try {
       return  await axios.editorapi.post(`v4/resorts/touristic-objects/${touristicObjectUuid}`,data)
      } catch (error) {
        throw new Error(error)
      }
    },

    /* Translations */
    getTouristicObjectTranslation: async (touristicObjectUuid) => {
      try {        
       return  await axios.editorapi.get(`v4/resorts/touristic-objects/${touristicObjectUuid}/translations/0`,)
      } catch (error) {
        throw new Error(error)
      }
    },
    createTouristicObjectTranslation: async (touristicObjectUuid,translationData) => {      
      try {        
       return  await axios.editorapi.post(`v4/resorts/touristic-objects/${touristicObjectUuid}/translations/${translationData.idioma_id}`,translationData)
      } catch (error) {
        throw new Error(error)
      }
    },

    updateTouristicObjectTranslation: async (touristicObjectUuid,translationData) => {
      try {        
       return  await axios.editorapi.patch(`v4/resorts/touristic-objects/${touristicObjectUuid}/translations/${translationData.idioma_id}`,translationData)
      } catch (error) {
        throw new Error(error)
      }
    },
    deleteTouristicObjectTranslation: async (touristicObjectUuid,code) => {
      try {        
       return  await axios.editorapi.delete(`v4/resorts/touristic-objects/${touristicObjectUuid}/translations/${code}`)
      } catch (error) {
        throw new Error(error)
      }
    },

    /* Images */
    createTouristicObjectImage: async (touristicObjectUuid,imageData) => {      
      try {        
       return  await axios.editorapi.post(`v4/resorts/touristic-objects/${touristicObjectUuid}/images/${imageData.uuid}`,imageData)
      } catch (error) {
        throw new Error(error)
      }
    },
    updateTouristicObjectImage: async (touristicObjectUuid,imageData) => {      
      try {        
       return  await axios.editorapi.patch(`v4/resorts/touristic-objects/${touristicObjectUuid}/images/${imageData.uuid}`,imageData)
      } catch (error) {
        throw new Error(error)
      }
    },
    deleteTouristicObjectImage: async (touristicObjectUuid,imageUuid) => {      
      try {        
       return  await axios.editorapi.delete(`v4/resorts/touristic-objects/${touristicObjectUuid}/images/${imageUuid}`)
      } catch (error) {
        throw new Error(error)
      }
    },

     /* Types */
    getTouristicObjectTypes: async () => {
      try {
       return  await axios.editorapi.get(`v4/resorts/touristic-types/`)
      } catch (error) {
        throw new Error(error)
      }
    },
     /* Zones */
    getTouristicObjecZones: async (resortUuid) => {
      try {
       return  await axios.editorapi.get(`v4/resorts/${resortUuid}/zones`)
      } catch (error) {
        throw new Error(error)
      }
    },   
    /* Langs */
    getLangs: async () => {
      try {
       return  await axios.publicapi.get(`languages/v3`)
      } catch (error) {
        throw new Error(error)
      }
    },

    getRideThrills: async (params, page = 1) => {
      try {
       let response = await axios.editorapi.get(`v4/resorts/ride-thrills`,{ 
        params: {
          ...params,        
          page: page
        }
      })
      return response.data.object
      } catch (error) {
        throw new Error(error)
      }
    },  
    getRideAudiences: async (params, page = 1) => {
      try {
       let response = await axios.editorapi.get(`v4/resorts/ride-audiences`, 
       { 
        params: {
          ...params,             
          page: page
        }
      })
      return response.data.object
      } catch (error) 
      {
        throw new Error(error)
      }
    },  
    getRequirements: async (params, page = 1) => {
      try {
       let response = await axios.editorapi.get(
        `v4/resorts/requirements`, 
        { 
          params: {
            ...params,                
            page: page
          }
        })
        return response.data.object
      } catch (error) {
        throw new Error(error)
      }
    },

    getTouristicObjectSubTypes: async (params, page = 1) => {
      try {
       let response = await axios.editorapi.get(`v4/resorts/touristic-subtypes/`,
       { 
        params: {
          ...params,                
          page: page
        }}
       )
       return response.data.object
      } catch (error) {
        throw new Error(error)
      }
    },
}