var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.width }, [
    _c(
      "div",
      {
        staticClass: "bg-grey-light sm:w-5/5 mb-3 ",
        style: { backgroundColor: "white !important" }
      },
      [
        _c(
          "div",
          { staticClass: "xs:w-1/2 sm:w-5/5 mb-10" },
          [
            _c("span", { staticClass: "block mt-2" }, [
              _vm._v(_vm._s(_vm.$t("geofences.add-geofences.latitude")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", disabled: _vm.disabled },
              on: { change: _vm.handleLatChange },
              model: {
                value: _vm.map.lat,
                callback: function($$v) {
                  _vm.$set(_vm.map, "lat", $$v)
                },
                expression: "map.lat"
              }
            }),
            _c("span", { staticClass: "block mt-2" }, [
              _vm._v(_vm._s(_vm.$t("geofences.add-geofences.longitude")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", disabled: _vm.disabled },
              on: { change: _vm.handleLonChange },
              model: {
                value: _vm.map.lon,
                callback: function($$v) {
                  _vm.$set(_vm.map, "lon", $$v)
                },
                expression: "map.lon"
              }
            }),
            _c("span", { staticClass: "block mt-2 hidden" }, [
              _vm._v(_vm._s(_vm.$t("geofences.add-geofences.radius")))
            ]),
            _c(
              "div",
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full border-grey-ligh hidden",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.selectedRadius,
                      callback: function($$v) {
                        _vm.selectedRadius = $$v
                      },
                      expression: "selectedRadius"
                    }
                  },
                  _vm._l([50, 100, 200], function(value, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: value, text: value }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c(
              "GmapMap",
              {
                staticStyle: { "max-width": "100%", height: "300px" },
                attrs: {
                  center: { lat: _vm.map.lat, lng: _vm.map.lon },
                  zoom: _vm.zoom,
                  "map-type-id": "satellite",
                  options: { streetViewControl: false }
                },
                on: {
                  click: function(evt) {
                    return _vm.changeCoords(evt)
                  }
                }
              },
              [
                _c("GmapMarker", {
                  ref: "map",
                  attrs: {
                    draggable: true,
                    position:
                      _vm.google &&
                      new _vm.google.maps.LatLng(_vm.map.lat, _vm.map.lon)
                  },
                  on: {
                    dragend: function(evt) {
                      return _vm.changeCoords(evt)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }