<template>

    <div :class="width">
        <div class="bg-grey-light sm:w-5/5 mb-3 "  :style="{ 'backgroundColor': 'white !important'}">
            <div class="xs:w-1/2 sm:w-5/5 mb-10">                
                <span  class="block mt-2">{{ $t("geofences.add-geofences.latitude")}}</span>
                <vs-input type="number"  :disabled="disabled" class="w-full border-grey-light" @change="handleLatChange" v-model="map.lat"/>
                <span class="block mt-2">{{ $t("geofences.add-geofences.longitude")}}</span>
                <vs-input type="number" :disabled="disabled" class="w-full border-grey-light"  @change="handleLonChange" v-model="map.lon"/>
                <span class="block mt-2 hidden">{{ $t("geofences.add-geofences.radius") }}</span>
                <div  >
                    <vs-select              
                        disabled
                        v-model="selectedRadius" 
                        class="w-full border-grey-ligh hidden">
                        <vs-select-item v-for="(value,index) in [50,100,200]" :key="index" :value="value" :text="value"/>
                    </vs-select>
                </div>
            </div>
            

            <div class="w-full">
                <GmapMap 
                    :center="{lat: map.lat, lng: map.lon}" 
                    :zoom="zoom" 
                    map-type-id="satellite" 
                    style="max-width: 100%; height: 300px" 
                    :options="{streetViewControl: false}"
                    @click="(evt) => changeCoords(evt)">
                
                    <GmapMarker 
                        ref="map" 
                        :draggable="true" 
                        :position="google && new google.maps.LatLng(map.lat, map.lon)" 
                        @dragend="(evt) => changeCoords(evt)"
                    />
                </GmapMap>
            </div>
        </div>
    </div>

</template>
<script>
    import { gmapApi } from 'vue2-google-maps'


    export default {
        name: 'MapCoordinates',        
        props: {
            radius: {
                type: Boolean,
                required: false,
            },
            defaultCoordinates:{
                type: Object,
                required: false
            },        
            componentSize :{
                type: Object,
                required:false,
                default: () => {
                    return { width:'w-full vx-row md:w-2/3 lg:w-1/2'};
                }                
            },
            'disabled': {
                'type': Boolean,
                'default': false
            },
            zoom: {
                type: Number,
                default: 10
            }
            
        },  
       
        created(){
            this.map = {...this.defaultCoordinates}
            this.width = this.componentSize.width  
        },     
        watch:{
            defaultCoordinates() {                
                this.map.lat = parseFloat(this.defaultCoordinates.lat) 
                this.map.lon = parseFloat(this.defaultCoordinates.lon)
            },
            selectedRadius(newRadius){
                this.map.radius = newRadius
                this.updated()
            }
        },
        data: () => (
            {
                map: {
                    lat:0,
                    lon:0,
                    radius: 0
                },
                selectedRadius: 200,
            }
        ),
        computed: {
            google: gmapApi,
        },        
        methods:{
            changeCoords(evt, ) {
                if(!this.disabled){
                    this.map.lat = evt.latLng.lat().toFixed(6)
                    this.map.lon = evt.latLng.lng().toFixed(6)
                    this.updated()
                }
            },        
      
            handleLatChange(latEvent) {
                this.map.lat = latEvent.target.value;   
                this.updated()
            },
            handleLonChange(lonEvent) {
                this.map.lon = lonEvent.target.value;
                this.updated()
            },
            updated() {
                if (!this.validate()) {
                    return;
                }
                let mapData = {
                    'lat':this.map.lat,
                    'lon':this.map.lon,     
                    ...(this.radius ? {'radius' : this.selectedRadius} : null)               
                }

                this.$emit('updated',mapData)
            },
            validate() {
                if (this.map.lat < -90 || this.map.lat > 90) {
                    this.map.lat = 0;
                    this.$emit('invalidCoords',this.$t('geofences.add-geofences.invalidLatitude'))
                    return false
                }
                if (this.map.lon < -180 || this.map.lon > 180) {
                    this.map.lon = 0;
                    this.$emit('invalidCoords',this.$t("geofences.add-geofences.invalidLongitude"))

                    return false
                }
                return true
            }         
        }
    }
</script>